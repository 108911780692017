



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 15px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-collapse-item__header {
  height: 21px;
  line-height: 21px;
   background:none;
  .el-collapse-item__content {
    margin-bottom: 10px !important;
  }
}
.el-collapse-item__wrap {
  background:none;
}
.remind {
    .el-form-item__content {
        line-height: 1.5rem;
        .colorkey {
            color:tomato
        }
    }
}
.rejectBox {
  display: flex;
  align-items: center;
  span {
    padding-left:.25rem;
    font-size: .9rem;
  }
}
.el_CardRed {
  background-color:#FAEBEB;
  color:#F36464;
  border:1px solid #FAEBEB
}
.el_CardGreen {
  background-color:#EAF7F4;
  color: #5DD9B9;
  border:1px solid #EAF7F4
}
.el_CardBlue {
  background-color:#EAEFFB;
  color:#598EFE;
  border:1px solid #EAEFFB
}
.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
