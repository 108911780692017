

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}

